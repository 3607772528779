import React, { Component } from "react"
import { css } from '@emotion/react'
import { navigate } from "../../helpers/queryNavigate"


// FINANCIERA COMPONENTS
import { useTheme, withThemeProps } from '@findep/microfronts-core'
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS
import { Grid, Container, Backdrop, Snackbar, Box, Typography,FormGroup, FormControlLabel, Checkbox} from "@material-ui/core"
import { Alert } from '@material-ui/lab';

//ICONS MATERIAL UI
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';

//SERVICIOS 
import { CataloguesService } from '../../services/LandingV4'
import { restoreFromSessionStorage } from "../../components/pages/afi/restoreSessionStorage"
import { iconStyle, h1Style, root } from '../../styles/lo-sentimos'


function VideoCallContent({videoCallContent}) {
  const { palette } = useTheme()

  const {
    textCallContent1,
    textCallContent2
  } = videoCallContent

  const Rounded = css`
   background: #476013;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;   
  `
  const cntTxtTwo = css`  
    width: 224px;  
    margin: 20px 20px 19px 16px;
    font-weight: normal;  
    color: ${palette.primary.main};
  `



  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={1}>
          <p css={Rounded}>1</p>
        </Grid>
        <Grid item  >
          <p css={cntTxtTwo}>
            {textCallContent1}
          </p>

        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} >
        <Grid item xs={1}>
          <p css={Rounded}>2</p>
        </Grid>
        <Grid item >
          <p css={cntTxtTwo}>
            {textCallContent2}
          </p>

        </Grid>
      </Grid>
    </div>
  )
}

const sizeGrl = css`  
   font-size: 16px;
    `
const cntTxtOne = css`  
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        line-height: 1.5;
        letter-spacing: 0.15px;
    `
const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
class TwilioVerifiy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flujo: restoreFromSessionStorage('flujo'),
      habilita: false,
      bdHabilita: false,
      errorService: false,
      severity_options: "warning",
      alert_text: this.props.alertText,
      terminos: false
    }
  }

  async stepCall() {

    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId

    this.setState({ habilita: true, bdHabilita: true })
    const catalogues = new CataloguesService();
    catalogues.identityVirtual(id)
      .then((response) => {
        let urlTwilio = response.data.verificationUrl
        // window.open(urlTwilio, '_blank', 'rel = nofollow noopener noreferrer');
        // navigate(this.props.pageContext.next.path)
        window.location = urlTwilio
        // this.setState({ errorService: true, habilita: false, bdHabilita: false ,severity_options:"success",alert_text: "Mensaje enviado correctamente"})
      })
      .catch(error => {
        this.setState({ errorService: true, habilita: false, bdHabilita: false ,severity_options:"warning",alert_text: this.props.alertText})
      })

  }

  //INICIANDO EL READY 
  componentDidMount() {
    let fechaCad = new Date()
    let hora = fechaCad.getHours()
    let dia = fechaCad.getDay()

    let nhora = Number.parseInt(hora)

    // let nhora = 22

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(fechaCad - tzoffset)).toISOString().slice(0, -1);

    if (dia !== 7) {
      if (dia === 6) {
        // console.log("Sabado")
        if (nhora < 8 || nhora > 19) {
          // console.log("hora no permitido")
          this.setState({ habilita: true });
        } else {
          this.setState({ habilita: false });
        }
      } else {
        // console.log("entre semana")
        if (nhora < 8 || nhora > 21) {
          // console.log("hora no permitido")
          this.setState({ habilita: true });
        } else {
          this.setState({ habilita: false });
        }
      }

    } else {
      this.setState({ habilita: true });
    }


  }

  handleChange(prop, data) {
    const state = {
      [prop]: data.value
    }    
    const tmp = Object.assign({}, this.state, state)
    this.setState(tmp, () => {    })
  }

  render() {
    const {
      titleIconCard1,
      textInfoCard1,
      textButton,
      titleIconCard2,
      textInfoCard2,
      alertText,
      videoCallContent,
    } = this.props
    return (
      <Container maxWidth="sm" justify="center" css={sizeGrl}>

        <Grid container justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <TitleIcon icon={SwitchVideoIcon} title={titleIconCard1} size={20} textColor />
          </Grid>
        </Grid>
        <br />
        <CardElevation>
          <Grid container spacing={3}>
            <Grid item  >
              <p css={cntTxtOne}>
                {textInfoCard1}
              </p>
            </Grid>
          </Grid>
          <div>
            <VideoCallContent videoCallContent={videoCallContent} />
            <br />
            <Grid item xs={12} >
                    <FormGroup >
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={this.state.terminos_phone_hlp}
                            onChange={(_event, component) => this.handleChange('terminos', { value: _event.target.checked })}
                            id="tus-datos-terminos-phone-checkbox"
                            name="tus-datos-terminos-phone-checkbox"
                            color="primary"
                            // disabled={this.state._errors.fechaNacimiento.error}
                          />
                        }
                        label={<span style={{ fontSize: '14px' }}>
                          Acepto expresamente el <a id="tus-datos-id-avisos-checkbox" href='/es/terminos-condiciones?option=privacy-notice' rel="nofollow noopener noreferrer" target="_blank" style={{ color: "#bf0000" }}>aviso de privacidad </a> de Apoyo Financiero, Inc.
        
                        </span>}
                      >
                      </FormControlLabel>
                    </FormGroup>
                  </Grid>
            <br/>
            <Grid container spacing={4} justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <ButtonDegrade 
                    id="video-llamada-button" 
                    textButton={textButton} 
                    onClick={() => this.stepCall()} 
                    disabled={!this.state.terminos} 
                    icon={CameraEnhanceIcon}                     
                  />
              </Grid>
            </Grid>
          </div>

        </CardElevation>
        <br />
        <CardElevation>
          <Grid container justify="center">
            <Grid item style={{ textAlign: "center" }}>
              <div css={root}>
                  <WarningIcon css={iconStyle} />
                  <Typography css={h1Style} component='span'>
                      {titleIconCard2}
                  </Typography>      
              </div>

              {/* <TitleIcon icon={WarningIcon} title={titleIconCard2} size={20} textColor /> */}
              <br />
            </Grid>
            <br />
            <Box textAlign="center">
              {textInfoCard2}
            </Box>
          </Grid>
        </CardElevation>
        <br />
        <Backdrop css={bDrop} open={this.state.bdHabilita} >
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity={this.state.severity_options} onClose={() => this.setState({ errorService: false })}>
            {this.state.alert_text}
          </Alert>
        </Snackbar>

        &nbsp;

      </Container>


    )
  }
}

TwilioVerifiy.defaultProps = {
  titleIconCard1: "Video llamada",
  textInfoCard1: 'Para validar tu identidad te enlazaremos en una video llamada',
  textButton: 'INICIAR VIDEO LLAMADA',
  titleIconCard2: 'Recuerda',
  textInfoCard2: (<>Haz clic <a href="https://drive.google.com/file/d/1GfHzRGKYOKRLWNP1qsmMQpvUtF0oflxG/view?usp=sharing" target="_blank" rel="nofollow noopener noreferrer">aquí</a> para ver un video que explica paso a paso el proceso de video verificación.<br />
  Recuerda que para continuar necesitas habilitar los permisos de tu cámara y estar en un lugar iluminado.</>),
  alertText: 'Lo sentimos, por el momento el servicio no se encuentra disponible',
  videoCallContent:{
    textCallContent1: 'Recuerda que los horarios de atención son: Lunes a Viernes 8:00am a 10:00pm y Sábado de 8:00am a 8:00pm.',
    textCallContent2: 'Al hacer clic en el botón, iniciarás la video llamada en otra pestaña de tu navegador.'
  }
}

export default withThemeProps(TwilioVerifiy,'VDNPVideoLlamada') 